.App {
  text-align: center;
}
body {
  background-color: black;
  color: white !important;
  box-sizing: border-box;
  font-family: 'Source Code Pro';
}

#root {
  background-color: white;
  color: black !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100%;
  padding: 0 20px 20px 20px;
  box-sizing: border-box;
}

#logo_image {
  width: 5%;
}

.dollar {
  color: #a0f01c;
  font-size: 73px;
  font-weight: 900;
  font-family: system-ui;
}

.dollar-label {
  margin: auto;
  font-size: 60px;
  font-weight: 900;
  font-family: system-ui;
  letter-spacing: 2px;
  position: absolute;
  text-align: center;
  width: 100%;
  top: -30px;
}

.dollar-box {
  border-radius: 20px;
  height: 220px;
  margin-left: 58px;
  background: rgb(51, 51, 51);
  box-shadow: 0px 0px 29px #aaa;
  margin-top: 25px;
  margin-bottom: 75px;
}

#scroll_image {
  width: 21px;
  bottom: 57px;
  position: absolute;
  margin-left: -13px;
}

.display-letter {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 100px;
  font-size: 14px;
  letter-spacing: 0px;
  color: #888888;
}

#logo_part {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 50px;
}

#background-image {
  background-image: url('../HeroCal.jpeg');
  width: 100vw;
  height: 400px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

#vivint-energy-img {
  background-image: url('../vitl_logo.png');
  margin-top: 15px;
  width: 100vw;
  height: 65px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-ai-fs {
  align-items: flex-start;
}

.flex-ai-center {
  align-items: center;
}

.flex-jc-center {
  justify-content: center;
}

.flex-jc-fs {
  justify-content: flex-start;
}

.flex-jc-se {
  justify-content: space-evenly;
}

.flex-jc-sb {
  justify-content: space-between;
}

.width35 {
  width: 35%;
}

.width50 {
  width: 50% !important;
}

.width60 {
  width: 60%;
}

.width80 {
  width: 80%;
}

.width70 {
  width: 70%;
}

.width85 {
  width: 85%;
}

.width90 {
  width: 90%;
}

.width100 {
  width: 100% !important;
}

.width50 {
  width: 50%;
}

.width30 {
  width: 30%;
}

.width45 {
  width: 45%;
}

.width47 {
  width: 47%;
}

.width48 {
  width: 48%;
}

.width20 {
  width: 20%;
}

.margin-right0 {
  margin-right: 0 !important;
}

.margin-right10 {
  margin-right: 10px !important;
}

.color-white {
  color: white;
}

.color-black {
  color: black !important;
}

.border-white {
  border: 1px solid white;
}

.border-gray {
  border: 2px solid #222;
}

.border-light-gray {
  border: 2px solid #777;
}

.border-round {
  border-radius: 25px !important;
}

.margin-top {
  margin-top: 35px !important;
}

.padding15 {
  padding: 15px;
}

.padding5 {
  padding: 5px;
}

.border-box {
  box-sizing: border-box;
}

.margin0 {
  margin: 0;
}

.flex-row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.bg-gray {
  background: #f9f9f9;
}

.bold {
  font-weight: bold;
}

.css-1yjjitx-MuiSwitch-track {
  background-color: white !important;
}

/******** Patch Code *****/
#root {
  background-color: #050505;
}

h1,
h2,
h3,
h4,
p {
  color: #fff;
  letter-spacing: -1.8px;
}

#background-image {
  margin-bottom: 2rem;
}

p span {
  font-size: 12px;
  color: #888888;
}

/******** Buttons *****/
.MuiButtonBase-root.MuiButton-root {
  background-color: #050505;
  color: #fff;
  border: solid 2px #fff;
  border-radius: 5px;
  opacity: 0.7;
  font-size: 0.85rem;
  padding: 2px 10px;
}

/* .MuiButtonBase-root.MuiButton-root:hover{
  background-color: #FFF;
  border: solid 2px #FFF;
  color: #050505;
} */

/* Selected Button */
.MuiButtonBase-root.MuiButton-root.MuiButton-contained {
  opacity: 1;
  background-color: #f9f9f9;
  border: solid 2px #f9f9f9;
  color: #050505;
}

#submit-payscale {
  color: black;
  border: solid 2px #e0ded4;
  background-color: #a0f01c;
  border-radius: 30px;
  border: none;
  padding: 8px;
}
/******** END Buttons *****/

#calculator button {
  text-transform: none;
}
/******** Input Fields *****/
.MuiInputBase-root.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-width: 2px;
  opacity: 0.7;
  border-radius: 60px;
}
#calculator .MuiInputBase-root.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #fff;
  border-radius: 5px;
}
/* #calculator .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
  border-color: #FFF;
  opacity: 1;
} */
#calculator .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #fff;
  opacity: 1;
}
#calculator .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined {
  color: #fff;
}
#calculator .MuiFormControl-root.MuiTextField-root label,
#calculator .MuiFormControl-root.MuiFormControl-fullWidth.css-q8hpuo-MuiFormControl-root label {
  color: #fff;
  padding: 0.2rem;
}
#calculator .MuiSelect-nativeInput,
#calculator .MuiInputBase-input.MuiOutlinedInput-input {
  color: #fff;
  padding: 8.5px 14px;
}
#calculator .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  color: #fff;
  margin-left: 2rem;
}
#calculator #demo-simple-select {
  height: 34px !important;
  padding: 1.5px 14px;
}
/******** END Input Fields *****/

/******** Slider *****/
.MuiSlider-root.MuiSlider-colorPrimary.MuiSlider-sizeMedium {
  color: #fff;
}
/******** END Slider *****/

/******** Table *****/
.MuiTable-root {
  border: solid 2px #fff;
}

.MuiTable-root {
  min-width: unset !important;
}
.MuiTable-root thead tr th,
.MuiTable-root tbody tr th,
.MuiTable-root tbody tr td {
  padding: 3px 5px;
  text-align: center;
}
/******** END Table *****/

.absolute {
  position: absolute;
}

.top-25 {
  top: 25px;
}

.right-25 {
  right: 15px;
}

.cursor-pointer {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .width70 {
    width: 100%;
  }

  h3 {
    margin: 0.5rem 0;
  }
}

/* .MuiButtonBase-root.MuiButton-root:hover{
  background-color: black;
  border: solid 2px #FFF;
  color: white;
} */

@media only screen and (max-width: 770px) {
  #background-image {
    height: 200px;
    margin-bottom: 0;
  }
}
#root {
  padding: 0 15px 20px 15px;
}
h1 {
  margin-bottom: 0;
}
h3 {
  margin: 0.5rem 0;
}
/* h1, h2, h3, h4, h5, h6, p{
    text-align: center;
  } */
.flex-row {
  /* flex-direction: column;
    align-items: center; */
  /* flex-wrap: wrap; */
}
.flex-row h3 {
  margin: 0;
}
.flex-row button,
#leadership-buttons button,
.flex-row-wrap button {
  width: 48%;
  margin-bottom: 1rem;
  font-size: 12px;
}
.pp-flex-row button {
  width: 48%;
  height: 34px;
  font-size: 0.67rem !important;
}
.flex-jc-sb button {
  /* margin-right: 1rem; */
}
/* } */

/* tracker */
.MuiSlider-track,
.MuiSlider-thumb {
  color: #a0f01c;
}

#payscale-table {
  margin: 20px 0px;
}

.MuiTableRow-root:nth-of-type(6) td,
.MuiTableRow-root:nth-of-type(6) th {
  background-color: #a0f01c !important;
  color: black !important;
}

.commission-breakout {
  background-color: #25282e;
  padding: 20px;
}

.commission-breakout p {
  color: #8a8c91;
  margin-top: 8px;
  margin-bottom: 8px;
}

p.payment-timing {
  font-size: 20px;
  color: white;
}

.commission-table div {
  border-bottom: 1px solid #8a8c91;
}

.commission-table div:last-child {
  border-bottom: none;
}

.uncommon svg {
  margin-left: 0 !important;
  color: white;
}

.uncommon .MuiFormControlLabel-label {
  color: white !important;
}

.uncommon.Mui-disabled svg {
  margin-left: 0 !important;
  color: #888888 !important;
}

.uncommon.Mui-disabled .MuiFormControlLabel-label {
  color: #888888 !important;
}

@media only screen and (max-width: 1264px) {
  #logo_image {
    width: 10%;
  }
  .width35 {
    width: 90%;
  }
  .width80 {
    width: 100%;
  }
  .width45 {
    width: 47%;
  }
  .dollar-box {
    margin-left: 0px;
  }
  .dollar-label {
    top: -14px;
    font-size: 50px;
  }
  .dollar {
    font-size: 50px;
  }
  .freedom-lead {
    width: 70% !important;
  }
  .manufacture-by {
    font-size: 12px;
  }
  #calculator button {
    font-size: 11px;
  }
}

@media only screen and (max-width: 500px) {
  .lead-type-label {
    margin-bottom: 36px;
  }
}
